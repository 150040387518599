document.addEventListener('DOMContentLoaded', function() {
    const timers = document.querySelectorAll('.promo-timer');

    timers.forEach(timer => {
        const endTime = new Date(timer.dataset.endTime).getTime();

        const updateTimer = function() {
            const now = new Date().getTime();
            const distance = endTime - now;

            if (distance < 0) {
                clearInterval(timerInterval);
                timer.querySelector('.promo-timer__box').innerHTML = "Promocja zakończona";
                return;
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
            const seconds = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0');

            const daysSpan = timer.querySelector('.js-promo-day');
            if (daysSpan) {
                daysSpan.textContent = days;
            }
            timer.querySelector('.js-promo-hour').textContent = hours;
            timer.querySelector('.js-promo-min').textContent = minutes;
            timer.querySelector('.js-promo-sec').textContent = seconds;
        };

        const timerInterval = setInterval(updateTimer, 1000);
    });

    document.querySelectorAll('.js-promo-code').forEach(function(item) {
        item.addEventListener('click', function() {
            const promoCode = item.querySelector('.promo__code-text').textContent;
            navigator.clipboard.writeText(promoCode).then(function() {
                item.querySelector('.js-promo-code-copied').classList.add('-active');
            }).catch(function(error) {
                console.error('Error copying text: ', error);
            });
        });
    });
});
